import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { ImageAsset, MarkdownContent } from '@shapeable/web';
import { SubTopic } from '@shapeable/gesda-types';

// -------- Types -------->

export type SubTopicAnticipationScoresLayoutProps = Classable & HasChildren & {
  entity: SubTopic;
}

export const SubTopicAnticipationScoresLayoutDefaultProps: Omit<SubTopicAnticipationScoresLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    flex-direction: row;
    justify-content: space-between;
  `,
});

const HeadingStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('serif')};
    font-weight: 400;
    font-size: 1.4em;
    margin-top: 0;
    margin-bottom: 1em;
  `,
});

const BodyStyles = breakpoints({
  base: css`

  `,
  tablet: css`
    width: 55%;
  `,
});

const TextStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text')};
    font-weight: 300;
    line-height: 1.45em;
  `,
});

const ImageStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(8)} ${theme.UNIT(2)} ${theme.UNIT(4)};
  `,
  tablet: css`
    margin: ${theme.UNIT(2)};
    width: 33%;
  `
});


// -------- Components -------->

const My = {
  Container: styled.div<any>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      Heading: styled.h1`${HeadingStyles}`,
      Text: styled(MarkdownContent)`${TextStyles}`,
    Image: styled(ImageAsset)`${ImageStyles}`,
};

export const SubTopicAnticipationScoresLayout: React.FC<SubTopicAnticipationScoresLayoutProps> = (props) => {
  const { className, entity } = props;
  const { name, anticipationScores, anticipationScoresImage } = entity;
  const text = anticipationScores.text;

  return (
    <My.Container className={className}>
      <My.Body>
        <My.Heading>{name} - Anticipation Scores</My.Heading>
        <My.Text text={text} />
      </My.Body>

      <My.Image entity={anticipationScoresImage} />
    </My.Container>
  )
};

SubTopicAnticipationScoresLayout.defaultProps = SubTopicAnticipationScoresLayoutDefaultProps;