import * as React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  EntityProvider, FeedEntryGridLayout, HorizonIndicators, ItemList, MainAside, ParentCard,
  PersonLink, PageContentLayout, createSlot, useSlots, withOwnProvider
} from '@shapeable/web';

import { HorizonGridLayout } from './horizon-grid-layout';
import { Helmet } from 'react-helmet';
import { SubTopicBannerLayout } from './sub-topic-banner-layout';
import { mapValues } from 'lodash';
import { SubTopic } from '@shapeable/gesda-types';

// -------- Types -------->

export type SubTopicLayoutBaseProps = HasChildren & {
  entity: SubTopic;
  asideHead?: React.ReactNode;
  asideFoot?: React.ReactNode;
  feedEntriesHead?: React.ReactNode;
  contentHead?: React.ReactNode;
  contentFoot?: React.ReactNode;
}

// -------- Components -------->

const ContainerStyles = breakpoints({
  base: css`

`,
});



const BodyStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)} ${theme.UNIT(3)};
  `,
  tablet: css`
    padding: ${theme.UNIT(6)} ${theme.UNIT(4)};
  `
});


const HeaderStyles = breakpoints({
  base: css`
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('serif')};
    margin: 0;
  `,
});

const BannerStyles = breakpoints({
  base: css`
    
  `,
});

const MainAsideStyles = breakpoints({
  base: css`
  `,
  desktop: css`
  `,
});

const MainStyles = breakpoints({
  base: css`
    
  `,
});

const AsideStyles = breakpoints({
  base: css`
    
  `,
});

const PeopleStyles = breakpoints({
  base: css`
    
  `,
});

const PersonStyles = breakpoints({
  base: css`
    
  `,
});

const FeedEntriesStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(8)};
  `,
});

const HorizonsStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(8)};
    margin-bottom: ${theme.UNIT(8)};
  `,
});

const ContentStyles = breakpoints({
  base: css`
    
  `,
});

const TopicStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
  tablet: css`
    margin-right: -${theme.UNIT(4)};
  `,
});


const HorizonIndicatorsStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const My = {
  Container: styled.div`${ContainerStyles}`,
    Header: styled.header`${HeaderStyles}`,
      Banner: styled(SubTopicBannerLayout)`${BannerStyles}`,
      Title: styled.h1`${TitleStyles}`,

    Body: styled.div`${BodyStyles}`,
      MainAside: styled(MainAside)`${MainAsideStyles}`,
        Main: styled.div`${MainStyles}`,
          Content: styled(PageContentLayout)`${ContentStyles}`,
        Aside: styled.div`${AsideStyles}`,
          People: styled(ItemList)`${PeopleStyles}`,
            Person: styled(PersonLink)`${PersonStyles}`,
          Topic: styled(ParentCard)`${TopicStyles}`,

      Horizons: styled(HorizonGridLayout)`${HorizonsStyles}`,
        HorizonIndicators: styled(HorizonIndicators)`${HorizonIndicatorsStyles}`,

      FeedEntries: styled(FeedEntryGridLayout)`${FeedEntriesStyles}`,

};

// -------- Slots ------>

const slots = {
  AsideHead: createSlot(),
  AsideFoot: createSlot(),
  ContentHead: createSlot(),
  ContentFoot: createSlot(),
  FeedEntriesHead: createSlot(),
};


export const SubTopicLayoutBaseComponent: React.FC<SubTopicLayoutBaseProps> = (props) => {
  
  const { entity, children } = props;

  const { topic, color, banner, people = [], feedEntries, horizons } = entity;

  const Slots = useSlots(slots);
  
  const asideHead = props.asideHead !== undefined ? props.asideHead : <Slots.AsideHead />;
  const asideFoot = props.asideFoot !== undefined ? props.asideFoot : <Slots.AsideFoot />;
  const contentHead = props.contentHead !== undefined ? props.contentHead : <Slots.ContentHead />;
  const contentFoot = props.contentFoot !== undefined ? props.contentFoot : <Slots.ContentFoot />;
  const feedEntriesHead = props.feedEntriesHead !== undefined ? props.feedEntriesHead : <Slots.FeedEntriesHead />;

  const hasBanner = banner && banner.image;
  const hasPeople = !!people.length;
  const hasFeedEntries = !!feedEntries.length;
  const hasHorizons = !!horizons.length;

  return (
    <EntityProvider value={entity}>
    <My.Container>
      {
        color && 
        <Helmet>
          <meta name="theme-color" content={color && color.value} />
        </Helmet>
      }
 
      <My.Header>
        { 
          hasBanner && 
          <My.Banner entity={entity} />
        }
      </My.Header>
          
      <My.Body>
      
        <My.MainAside
          main={
            <My.Main >
              {contentHead}
              <My.Content entity={entity} />
              {contentFoot}
            </My.Main>
          }
          aside={
            <My.Aside>
            {asideHead}
            {
              hasPeople && 
              <My.People items={people.map(person => <My.Person showArrow entity={person} />)} />
            }
            {
              topic && 
              <My.Topic label="Parent Topic:" entity={topic} />
            }
            {asideFoot}
            </My.Aside>
          }
        />

        {
          hasHorizons && 
          <My.Horizons 
            items={horizons}>
            <My.HorizonIndicators />
          </My.Horizons>
        }
        {children}
        {feedEntriesHead}
        {
          hasFeedEntries && 
          <My.FeedEntries items={feedEntries} />
        }
    
      </My.Body>
    </My.Container>
    </EntityProvider>
  );
};

export const SubTopicLayoutBase = Object.assign(
  withOwnProvider<SubTopicLayoutBaseProps>(SubTopicLayoutBaseComponent), {
  ...mapValues(slots, slot => slot.Provider),
});



