import * as React from 'react';
import { HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { SubTopic } from '@shapeable/gesda-types';
import { SubTopicAnticipationScoresLayout } from './sub-topic-anticipation-scores-layout';
import { SubTopicLayoutBase } from './sub-topic-layout-base';
import { EntityConnectionsPanel } from './entity-connections-panel';

// -------- Types -------->

export type SubTopicLayoutProps = HasChildren & {
  entity: SubTopic;
}

// -------- Components -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const AnticipationScoresStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)} 0;
    border-top: 1px solid ${theme.COLOR('line-mid')};
    border-bottom: 1px solid ${theme.COLOR('line-mid')};
  `,
  desktop: css`
    padding: ${theme.UNIT(4)};
    border: 1px solid ${theme.COLOR('line-mid')};
  `,
});

const ConnectionsStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});


const My = {
  Container: styled(SubTopicLayoutBase)`${ContainerStyles}`,
  AnticipationScores: styled(SubTopicAnticipationScoresLayout)`${AnticipationScoresStyles}`,
  Connections: styled(EntityConnectionsPanel)`${ConnectionsStyles}`,
};

export const SubTopicLayout: React.FC<SubTopicLayoutProps> = (props) => {
  
  const { entity } = props;

  const { anticipationScores, anticipationScoresImage } = entity;
  const hasAnticipationScores = !!anticipationScores && !!anticipationScoresImage;
  
  return (
    <My.Container
      entity={entity}
      feedEntriesHead={
        hasAnticipationScores && 
        <My.AnticipationScores entity={entity} />
      }
      asideFoot={<My.Connections entity={entity} />}
    />
  );
};


