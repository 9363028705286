import React from 'react';
import styled, { css } from 'styled-components';
import { HasChildren, Classable, Dictionary } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Horizon } from '@shapeable/types';
import { Grid, GridProps, SectionTitle } from '@shapeable/web';
import { HorizonCard } from './horizon-card';
import { sortBy } from 'lodash';

// -------- Types -------->

export type HorizonGridLayoutProps = Omit<GridProps, 'items'> & {
  items: Horizon[];
  title?: string;
};

export const HorizonGridLayoutDefaultProps: HorizonGridLayoutProps = {
  items: [],
  title: 'Future Horizons:',
  spacing: 3,
  desktopAutoColumns: 3,
  tabletAutoColumns: 3,
  maxColumns: 3,
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const TitleStyles = breakpoints({
  base: css`
  `,
});

const GridStyles = breakpoints({
  base: css`
  `,
});

const CardStyles = breakpoints({
  base: css`
  `,
});



// -------- Components -------->

const My = {
  Container: styled.section`${ContainerStyles}`,
    Title: styled(SectionTitle)`${TitleStyles}`,

    Grid: styled(Grid)`${GridStyles}`,
      Card: styled(HorizonCard)`${CardStyles}`,
};

export const HorizonGridLayout: React.FC<HorizonGridLayoutProps> = (props) => {
  const { className, items, title, children, ...rest } = props;

  return (
    <My.Container className={className}>
      <My.Title>{title}</My.Title>
      {children}
      <My.Grid {...rest} items={sortBy(items, 'type.years').map(horizon => <My.Card entity={horizon} />)} />
    </My.Container>
  )
};

HorizonGridLayout.defaultProps = HorizonGridLayoutDefaultProps;