import React from 'react';
import { Classable, HasChildren, SubTopic } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { BannerControls, ImageAsset, OutlineNumber, ResponsiveContainer, SectionTitle, useFullscreen } from '@shapeable/web';

// -------- Types -------->

export type SubTopicBannerLayoutProps = Classable & HasChildren & { 
  entity: SubTopic;
};

export const SubTopicBannerLayoutDefaultProps: Omit<SubTopicBannerLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    background: #EBEBEB;
    display: flex;
    flex-direction: row;
  `,
});

const ControlsStyles = breakpoints({
  base: css`
  `,
});

const BannerContainerStyles = breakpoints({
  base: css`
  flex-grow: 1;
    position: relative;
    font-size: ${theme.FONT_SIZE(20)};
    border-bottom: 1px solid ${theme.COLOR('line-mid')};
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(24)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(36)};
  `,
});


const TitleStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text')};
    margin-bottom: 0;
    padding-left: ${theme.UNIT(2)};
  `,
  desktop: css`
    padding-left: 0;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: ${theme.UNIT(3)} ${theme.UNIT(0)};
  `,
  desktop: css`
    padding: ${theme.UNIT(13)} ${theme.UNIT(8)};
    top: 0;
    left: 0;
    position: absolute;
    width: 30%;
  `,
});

const HeadingStyles = breakpoints({
  base: css`
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${theme.UNIT(4)};
    line-height: 1.0em;
    padding-left: ${theme.UNIT(2)};
  `,
  desktop: css`
    margin-top: ${theme.UNIT(12)};
    padding-left: 0;
  `,
});

const OutlineNumberStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(3)};
  `,

});

const NameStyles = breakpoints({
  base: css`
    font-weight: 500;
    line-height: 1.1em;
    font-family: ${theme.FONT('serif')};
    font-size: ${theme.FONT_SIZE(21)};
    ${(props: any) => props.color && css`
      color: ${props.color};
    `}
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(24)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(36)};

    &.is-fullscreen {
      font-size: ${theme.FONT_SIZE(28)};
    }
  `
  
});

const BackgroundStyles = breakpoints({
  base: css`
    position: relative;
  `,
});


const ImageStyles = breakpoints({
  base: css`
    position: absolute !important;
    height: 100%;
    width: 100% !important;
    right: 0;
  `,
  desktop: css`
    width: 70% !important;
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    Controls: styled(BannerControls)`${ControlsStyles}`,
    BannerContainer: styled.div<any>`${BannerContainerStyles}`,
      Background: styled(ResponsiveContainer)`${BackgroundStyles}`,
        Image: styled(ImageAsset)`${ImageStyles}`,

      Body: styled.div`${BodyStyles}`,
        Title: styled(SectionTitle)`${TitleStyles}`,

        Heading: styled.h1`${HeadingStyles}`,
          OutlineNumber: styled(OutlineNumber)`${OutlineNumberStyles}`,
          Name: styled.span`${NameStyles}`,
};

export const SubTopicBannerLayout: React.FC<SubTopicBannerLayoutProps> = (props) => {
  const { className, entity } = props;

  const { banner, outlineNumber, name, color } = entity;

  const aspectRatio = {
    base: 17/7,
    desktop: 1398/386
  };

  const fs = useFullscreen();

  return (

    <My.Container className={className}>
      <My.BannerContainer>
      <My.Background aspectRatio={aspectRatio}>
        <My.Image entity={banner} />
      </My.Background>
      <My.Body>
        <My.Title>Sub-Field:</My.Title>
        <My.Heading>
          <My.OutlineNumber color={color} size="banner">{outlineNumber}</My.OutlineNumber>
          <My.Name color={color && color.value} className={fs.className}>{name}</My.Name>
        </My.Heading>
      </My.Body>
      </My.BannerContainer>
      <My.Controls />
    </My.Container>
  )
};

SubTopicBannerLayout.defaultProps = SubTopicBannerLayoutDefaultProps;